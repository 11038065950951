<template>
    
    <div class="container" key="arrangements">
        <div class="row section">

            <div class="col-12">
               <div class="alert alert-success">
                Op dit moment bieden wij helaas geen arrangementen aan in onze B&B.<br>
                We hopen dat we binnenkort weer arrangementen kunnen aanbieden en zullen dit op onze website en via onze social media kanalen laten weten.<br>
                </div>
            </div>

            <!--<div class="col-lg-5 col-sm-6">
                <div class="card">
                    <div class="card--top">
                        <background-image image="eenden.jpg" prefix="arrangementen/small/"></background-image>
                    </div>
                    <div class="card--body">
                        <h3>Kleine rondvaart</h3>
                        <span class="subtitle">Apart bij te boeken</span>
                        <p>
                            Gratis bij een boeking vanaf 3 nachten in de B&amp;B.
                        </p>
                    </div>
                    <div class="card--bottom text-center">
                        <router-link to="/arrangementen/kleine-rondvaart" tag="a" class="card--action">
                            <span>Lees meer</span>
                            <img src="@/assets/img/icons/search.svg" />
                        </router-link>
                        <router-link to="/reserveren" tag="a" class="card--action">
                            <span>Boek nu</span>
                            <img src="@/assets/img/icons/calendar-check.svg" />
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-lg-offset-2 col-sm-6">
                <div class="card">
                    <div class="card--top">
                        <background-image image="efteling-winter.jpg" prefix=""></background-image>
                    </div>
                    <div class="card--body">
                        <h3>Winter Efteling</h3>
                        <span class="subtitle">Gratis vanaf 7 nachten</span>
                        <p>
                            Geldig in de periode van 11-11-2019 t/m 2-2-2020.
                        </p>
                    </div>
                    <div class="card--bottom text-center">
                        <router-link to="/arrangementen/winter-efteling" tag="a" class="card--action">
                            <span>Lees meer</span>
                            <img src="@/assets/img/icons/search.svg" />
                        </router-link>
                        <router-link to="/reserveren" tag="a" class="card--action">
                            <span>Boek nu</span>
                            <img src="@/assets/img/icons/calendar-check.svg" />
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-lg-offset-2 col-sm-6">
                <div class="card">
                    <div class="card--top">
                        <background-image image="koe.jpg" prefix="arrangementen/small/"></background-image>
                    </div>
                    <div class="card--body">
                        <h3>Grote rondvaart</h3>
                        <span class="subtitle">Apart bij te boeken</span>
                        <p>
                            Een grote rondvaart door de Biesbosch met een gids.
                        </p>
                    </div>
                    <div class="card--bottom text-center">
                        <router-link to="/arrangementen/grote-rondvaart" tag="a" class="card--action">
                            <span>Lees meer</span>
                            <img src="@/assets/img/icons/search.svg" />
                        </router-link>
                        <router-link to="/reserveren" tag="a" class="card--action">
                            <span>Boek nu</span>
                            <img src="@/assets/img/icons/calendar-check.svg" />
                        </router-link>
                    </div>
                </div>
            </div>-->
        </div>
    </div>

</template>

<script>
export default {
    metaInfo: {
        title: 'Arrangementen',
        meta: [
            { vmid: 'description', name: 'description', content: 'We bieden verschillende arrangementen in de vorm van een rondvaart door de Biesbosch. Op plekken waar u normaal gelegen niet gemakkelijk komt.' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.deoudestoffeerderij.nl/arrangementen'}
        ]
    }
}
</script>
